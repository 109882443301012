<template>
  <div class="home-wrapper">
    <h2 class="home-title semi-40 color-gray-dark">
      {{ $t('welcome') }}, <span>{{ userData.name }}!</span>
    </h2>

    <p class="home-description medium-20 color-gray-dark">
      {{ $t('selectApp') }}
    </p>

    <h3 class="home-title-small semi-24 color-gray-dark">
      {{ $t('logInTo') }}
    </h3>

    <div class="home-services">
      <div class="home-services__item">
        <img
          v-if="!checkServiceExistence('xbody-id-portal')"
          width="24"
          height="24"
          src="~@/assets/imgs/lock.svg"
          alt=""
        />
        <a
          href="https://asset.xbodyworld.com/"
          class="d-flex align-center justify-center"
          target="_blank"
        >
          <div class="home-services__img">
            <img src="~@/assets/imgs/xbody-asset.svg" alt="service" />
          </div>
        </a>
      </div>
      <div class="home-services__item">
        <img
          v-if="!checkServiceExistence('lms-portal')"
          width="24"
          height="24"
          src="~@/assets/imgs/lock.svg"
          alt=""
        />
        <a
          :href="lms + '/login'"
          class="d-flex align-center justify-center"
          target="_blank"
        >
          <div class="home-services__img">
            <img src="~@/assets/imgs/xbody-asset-1.svg" alt="service" />
          </div>
        </a>
      </div>
      <!-- <div class="home-services__item">
        <img
          v-if="!checkServiceExistence('fit-portal')"
          width="24"
          height="24"
          src="~@/assets/imgs/lock.svg"
          alt=""
        />
        <a
          href="https://fit.xbodyworld.com"
          class="d-flex align-center justify-center"
          target="_blank"
        >
          <div class="home-services__img">
            <img src="~@/assets/imgs/xbody-asset-3.svg" alt="service" />
          </div>
        </a>
      </div> -->
      <div class="home-services__item">
        <img
          v-if="!checkServiceExistence('studion')"
          width="24"
          height="24"
          src="~@/assets/imgs/lock.svg"
          alt=""
        />
        <a
          :href="studion + '/login'"
          class="d-flex align-center justify-center"
          target="_blank"
        >
          <div class="home-services__img">
            <img src="~@/assets/imgs/studioon-logo.png" alt="service" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  data() {
    return {
      studion: process.env.VUE_APP_STUDION_URL,
      lms: process.env.VUE_APP_LMS_URL,
    };
  },
  methods: {
    ...mapActions(['updateShowLoader']),
    checkServiceExistence(name) {
      let exist = false;
      this.userData.services.forEach((service) => {
        if (service.name == name) {
          exist = true;
        }
      });
      return exist;
    },
  },
  computed: {
    ...mapGetters(['userData']),
  },
  mounted() {
    this.updateShowLoader(false);
  },
};
</script>

<style lang="scss" src="./HomeView.scss" scoped></style>
