import axios from 'axios';
import { baseUrl } from '@/config';

axios.defaults.baseURL = baseUrl;
axios.defaults.withCredentials = true;

export default {
  async request({ url, data, params, method, fileType }) {
    let response = null;
    try {
      const body = {
        url,
        data,
        params,
        method,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Credentials': 'true',
          'X-XSRF-TOKEN': 'X-XSRF-TOKEN',
        },
      };
      if (fileType) {
        body.headers = {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Credentials': 'true',
          'X-XSRF-TOKEN': 'X-XSRF-TOKEN',
          'Content-Type': 'multipart/form-data',
        };
      }
      response = await axios(body);
      return response;
    } catch (err) {
      const { response } = err;
      const { status } = response;
      if (status === 401) {
        localStorage.clear();
        location.replace('/login');
        return;
      }
      return response;
    }
  },

  get(url, params, fileType = false) {
    const body = { url, params, fileType, method: 'get' };
    return this.request(body);
  },

  async post(url, data, fileType = false) {
    const body = { url, data, method: 'post', fileType };
    const response = await this.request(body);

    return response;
  },

  delete(url) {
    const body = { url, method: 'delete' };
    return this.request(body);
  },

  async patch(url, data) {
    const body = { url, method: 'patch', data };
    const response = await this.request(body);
    return response;
  },

  getCookie() {
    return this.get('frontend/csrf-cookie');
  },

  getServices(params) {
    return this.get('frontend/services', params);
  },

  getInvoices(params) {
    return this.get('frontend/invoices', params);
  },

  getInvoiceData(id) {
    return this.get(`frontend/invoices/${id}`);
  },

  getUserData(id) {
    return this.get(`frontend/users/${id}`);
  },

  userRegistration(userData) {
    return this.post('frontend/register', userData);
  },

  userLogin(userData) {
    return this.post('frontend/login', userData);
  },

  logout(userData) {
    return this.post('frontend/logout', userData);
  },

  emailVerification(postData) {
    return this.post('frontend/email/verification-notification', postData);
  },

  changePassword(id, patchData) {
    return this.post(`frontend/users/${id}/password-update`, patchData);
  },
  resetPassword(patchData) {
    return this.post(`frontend/reset-password`, patchData);
  },

  forgotPassword(patchData) {
    return this.post(`frontend/forgot-password`, patchData);
  },

  updateUserEmail(id, patchData) {
    return this.post(`frontend/users/${id}`, patchData);
  },

  updateUserAvatar(id, formData) {
    return this.post(`frontend/users/${id}/profile-photo`, formData, true);
  },

  fetchBillingInformationFront(id) {
    return this.get(`frontend/users/${id}/billingInformation/get`);
  },
  fetchSAP(id, code) {
    return this.get(`frontend/users/${id}/show-from-sap/${code}`);
  },
  editBillingInformation(id, formData) {
    return this.post(`frontend/users/${id}/billingInformation/edit`, formData);
  },
  storeBillingInformation(id, formData) {
    return this.post(`frontend/users/${id}/billingInformation/store`, formData);
  },
  BillingInformationNewSAP(id, formData) {
    return this.post(
      `frontend/users/${id}/billingInformation/newSAP`,
      formData
    );
  },
  freshBillingInformation(id, formData) {
    return this.post(
      `frontend/users/${id}/billingInformation/newSAP`,
      formData
    );
  },
  countries() {
    return this.get(`frontend/countries`);
  },
};
